* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --background-color: #FFF;
  --bubble-text-color: #FAFAFA;
  --blue: #142EEF;
  --green: #538083;
  --purple: #313558;

  --main-font: 'Space Mono', monospace;
  --secondary-font: 'Chivo', sans-serif;
  --third-font: 'Space Grotesk', sans-serif;

  --font-size-xl-mob: 1.875rem;
  --font-size-xl-desk: 2.375rem;
  --font-size-l-mob: 1rem;
  --font-size-l-desk: 1.25rem;
  --font-size-m-mob: 0.875rem;
  --font-size-m-desk: 1.125rem;
  --font-size-s-mob: 0.813rem;
  --font-size-s-desk: 1rem;


  --padding-side-mob: 1.25rem;
  --padding-left-desk: 2.5rem;
  --padding-right-desk: 3.75rem;
}

html {
  @media screen and (max-width: 368px) {
    font-size: calc(16 / 368 * 100vw);
  }

  @media screen and (min-width: 768px) and (max-width: 1232px) {
    font-size: calc(16 / 1232 * 100vw);
  }
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/Space_Mono/SpaceMono-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/Space_Mono/SpaceMono-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/Space_Mono/SpaceMono-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/Space_Mono/SpaceMono-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('../public/fonts/Space_Grotesk/SpaceGrotesk-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Chivo;
  src: url('../public/fonts/Chivo/Chivo-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Chivo;
  src: url('../public/fonts/Chivo/Chivo-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}